import React from "react";

const lead = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.width}
    height={props.height}
    className={props.class}
    viewBox="0 0 1345.84 248.19"
  >
    <defs></defs>
    <title>lead</title>
    <path
      d="M414.41,159.94c-29.18,0-48-20.75-48-49.29s20.11-49.3,48-49.3a44.07,44.07,0,0,1,31.4,13.1l-9.34,14a28.71,28.71,0,0,0-22.06-10.25c-18.93,0-30.48,14.27-30.48,32.43s12.2,32.43,30.48,32.43c9.61,0,16.22-3.24,22.06-10.25L447,145.68C440,155,427.26,159.94,414.41,159.94Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M510.93,159.94c-26.72,0-48-20.1-48-49.29s21.15-49.3,48-49.3,48,20.11,48,49.3S537.66,159.94,510.93,159.94Zm0-81.72c-16.6,0-30.48,12.58-30.48,32.43s13.75,32.43,30.48,32.43,30.49-12.58,30.49-32.43S527.67,78.22,510.93,78.22Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M640.66,157.35l-41.9-62.27h-.26v62.27H581V64h16.86l41.91,62.27H640V64h17.52v93.4Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M713.69,159.94c-15.18,0-23.35-6.48-31.14-15.43l11.68-11.68c3.89,5.06,8.94,10.25,19.46,10.25,6.48,0,12.32-3.89,12.32-11.67,0-17.52-38.92-13.63-38.92-44.11,0-16.35,12.19-25.95,26.6-25.95,11.68,0,19.45,3.89,25.94,11.68L729.9,84.7c-3.25-5.19-9.73-7.78-16.21-7.78-5.84,0-9.73,3.37-9.73,10.38,0,17.51,39.57,11.67,39.57,44.11C743.53,148.27,731.85,159.94,713.69,159.94Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M805.8,159.94c-20.77,0-37-11.67-37-36.32V64h17.52v59.67c0,13,8.42,20.11,19.46,20.11s19.46-7.14,19.46-20.11V64h17.51v59.67C842.77,148.27,826.55,159.94,805.8,159.94Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M950.15,157.35V95.08h-.25L921,141.78,892,95.08h-.27v62.27H874.26V64h17.51L921,112l29.19-48h17.52v93.4Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M1049.13,157.35H999.84V64h48l2.59,16.86h-33.08v20.76h29.19v16.86h-29.19v22.06h34.38Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M1124.77,157.35l-24-38.92h-1.29v38.92H1082V64h28.54c15.83,0,29.84,10.38,29.84,27.24,0,14-10.38,23.35-20.75,25.94l27.24,40.22ZM1107.9,78.22h-8.43v25.94h8.43c7.78,0,14.27-3.89,14.27-13S1115.68,78.22,1107.9,78.22Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M1169.78,157.35V64h17.51v93.4Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M1243.18,159.94c-15.17,0-23.35-6.48-31.13-15.43l11.68-11.68c3.89,5.06,8.94,10.25,19.45,10.25,6.49,0,12.33-3.89,12.33-11.67,0-17.52-38.92-13.63-38.92-44.11,0-16.35,12.2-25.95,26.59-25.95,11.68,0,19.47,3.89,26,11.68L1259.4,84.7c-3.24-5.19-9.73-7.78-16.22-7.78-5.83,0-9.72,3.37-9.72,10.38,0,17.51,39.56,11.67,39.56,44.11C1273,148.27,1261.35,159.94,1243.18,159.94Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M1348.83,157.35h-49.3V64h48l2.6,16.86H1317v20.76h29.19v16.86H1317v22.06h34.39Z"
      transform="translate(-5.58 -1.08)"
      fill="#f6f6f6"
    />
    <path
      d="M89.38,123.57a40.34,40.34,0,0,0,10.67,27.35l18-10.54a20.61,20.61,0,1,1,23.77,0l18,10.54a40.51,40.51,0,1,0-70.39-27.35Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M206.36,90.88a82.68,82.68,0,0,0-4.66-9.15,79.29,79.29,0,0,0-4.49-6.81l-17.9,10.52c.9,1.15,1.79,2.31,2.61,3.52s1.39,2.25,2.06,3.39h0a61.27,61.27,0,0,1,3.45,6.7A62.59,62.59,0,0,1,80.6,162.33l-17.91,10.5a83.21,83.21,0,0,0,143.67-82Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M109.57,1.08A124.44,124.44,0,1,0,232.91,193.8l-17.82-10.44A103.85,103.85,0,1,1,113.65,21.26Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M380.67,233v15.26h-6.75v-36h6.75v14.25h14.75V212.26h6.75v36h-6.75V233Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M437.42,235.26c0,9.51-6.25,14-14.25,14s-14.25-4.5-14.25-14v-23h6.75v23c0,5,3.25,7.76,7.5,7.76s7.5-2.75,7.5-7.76v-23h6.75Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M473.32,224.26l-11.15,18-11.15-18h-.1v24h-6.75v-36h6.75l11.25,18.5,11.25-18.5h6.75v36h-6.75v-24Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M493.82,240.27l-2.4,8h-7.25l12-36h7l12,36h-7.25l-2.4-8Zm1.8-6h8.1l-4-14h-.1Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M525.92,224.26v24h-6.75v-36h6.5l16.15,24h.1v-24h6.75v36h-6.5l-16.15-24Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M597.73,243.77c-2.7,3.6-7.61,5.5-12.56,5.5-11.25,0-18.5-8-18.5-19s7.75-19,18.5-19a17,17,0,0,1,12.11,5.05l-3.6,5.4a11.07,11.07,0,0,0-8.51-4c-7.3,0-11.75,5.5-11.75,12.5s4.7,12.51,11.75,12.51a10.16,10.16,0,0,0,8.51-4Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M603.22,212.26h18.51l1,6.5H610v8h11.25v6.5H610v8.51h13.25l-1,6.5h-19Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M635.48,224.26v24h-6.75v-36h6.5l16.15,24h.1v-24h6.75v36h-6.5l-16.15-24Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M670,248.27V218.76h-7.75l.75-6.5h20.75l.75,6.5h-7.75v29.51Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M688.48,212.26H707l1,6.5H695.23v8h11.25v6.5H695.23v8.51h13.25l-1,6.5h-19Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M720.73,233.26v15H714v-36h11c6.1,0,11.5,4,11.5,10.5a10.39,10.39,0,0,1-8,10L739,248.27h-8.5l-9.25-15Zm3.25-15.5h-3.25v10H724c3,0,5.5-1.5,5.5-5S727,217.76,724,217.76Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M743,212.26h18.5l1,6.5H749.73v8H761v6.5H749.73v8.51H763l-1,6.5H743Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M779.73,248.27H768.48v-36h11c8.5,0,18,6,18,18S788,248.27,779.73,248.27ZM778,218.51h-2.75V242H778c6,0,12.5-3.75,12.5-11.76S784,218.51,778,218.51Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M820.23,248.27V218.76h-7.75l.75-6.5H834l.75,6.5H827v29.51Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M838.73,212.26h18.5l1,6.5H845.48v8h11.25v6.5H845.48v8.51h13.25l-1,6.5h-19Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M893.78,243.77c-2.7,3.6-7.6,5.5-12.55,5.5-11.25,0-18.5-8-18.5-19s7.75-19,18.5-19a17,17,0,0,1,12.1,5.05l-3.6,5.4a11.07,11.07,0,0,0-8.5-4c-7.3,0-11.75,5.5-11.75,12.5s4.7,12.51,11.75,12.51a10.14,10.14,0,0,0,8.5-4Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M906,233v15.26h-6.75v-36H906v14.25h14.75V212.26h6.75v36h-6.75V233Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M941.28,224.26v24h-6.75v-36H941l16.16,24h.1v-24H964v36h-6.5l-16.16-24Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M988,249.27c-10.3,0-18.51-7.75-18.51-19s8.16-19,18.51-19,18.5,7.75,18.5,19S998.34,249.27,988,249.27Zm0-31.51c-6.4,0-11.75,4.85-11.75,12.5s5.3,12.51,11.75,12.51,11.75-4.85,11.75-12.51S994.49,217.76,988,217.76Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M1012,212.26h6.75v29.51h11.75l-1,6.5H1012Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M1051,249.27c-10.3,0-18.5-7.75-18.5-19s8.15-19,18.5-19,18.5,7.75,18.5,19S1061.34,249.27,1051,249.27Zm0-31.51c-6.4,0-11.75,4.85-11.75,12.5s5.3,12.51,11.75,12.51,11.75-4.85,11.75-12.51S1057.49,217.76,1051,217.76Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M1108.29,240.62c-3.7,4.95-7.75,8.65-16.75,8.65-9.9,0-18-8.2-18-19s8.1-19,18-19c8.1,0,12.65,3.15,15.3,6.85l-4.5,4.5c-2.25-3.15-5.4-4.85-10.8-4.85-6.75,0-11.25,5.75-11.25,12.5s4.5,12.51,11.25,12.51c3.7,0,7.45-1.1,10-4v-5.56H1095l1-5.75h12.25Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M1121.54,212.26v36h-6.75v-36Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M1128.54,212.26H1147l1,6.5h-12.75v8h11.25v6.5h-11.25v8.51h13.25l-1,6.5h-19Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M1163.79,249.27c-5.85,0-9-2.5-12-6l4.5-4.5a8.66,8.66,0,0,0,7.5,4c2.5,0,4.75-1.5,4.75-4.5,0-6.76-15-5.26-15-17,0-6.3,4.7-10,10.25-10a11.92,11.92,0,0,1,10,4.5l-3.75,4.5a7.27,7.27,0,0,0-6.25-3c-2.25,0-3.75,1.3-3.75,4,0,6.75,15.25,4.5,15.25,17C1175.29,244.77,1170.79,249.27,1163.79,249.27Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
    <path
      d="M1187.29,241.27v7h-6.5v-7Z"
      transform="translate(-5.58 -1.08)"
      fill="#e7343f"
    />
  </svg>
);

export default lead;
