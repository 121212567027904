import React from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import { Parallax, Background } from "react-parallax";
import backgroundImg from "../../assets/images/bg1.png";
import Lead from "../../assets/lead";

function App() {
  return (
    <Router>
      <div className="container-fluid">
        <Parallax
          className="react-parallax-nopadding"
          bgImage={backgroundImg}
          bgImageAlt=""
          strength={400}
        >
          <div className="row" style={{ height: "24vh" }}></div>
          <div className="row" style={{ height: "48vh" }}>
            <div className="col-sm-3"></div>
            <div className="mx-1 mt-n-1 col-sm-6 text-center align-middle">
              <Lead width="100%" height="100%" className=""></Lead>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </Parallax>
        <div className="row">
          <div className="col-sm-6 text-sm-right pr-5 text-secondary display-3 font-weight-bold pt-5">
            CONTACT
          </div>
          <div className="col-sm-6 text-left text-primary text-uppercase pt-3 pt-sm-5">
            
            <a className="text-primary" href="mailto:office@consumerise.de">
              {" "}
              OFFICE@CONSUMERISE.DE
            </a>
          </div>
        </div>
        <div className="row mt-5 pt-2 footer">
          <div className="col-12 text-right text-primary ">
            <a
              href="#"
              className="text-primary"
              data-toggle="modal"
              data-target="#impressumModal"
            >
              Imprint
            </a>
            &nbsp; | Consumerise GmbH | Copyright 2023
          </div>
        </div>
      </div>
      {/*include modal windows*/}
      {getImpressum()}
    </Router>
  );
}

/*-------Modal windows-----*/

function getImpressum() {
  return (
    <div
      className="modal fade"
      id="impressumModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="impressumModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title text-primary" id="impressumModal">
              Imprint
            </h5>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            Our Site,{" "}
            <a href="https://www.consumerise.de" target="_blank">
              www.consumerise.de
            </a>
            , is owned and operated by
            <br />
            Consumerise Gmbh
            <br />
            Schwetzingerstraße 46 <br />
            69190 Walldorf - Germany
            <br />
            <br />
            <br />
            E-Mail:{" "}
            <a href="mailto:office@consumerise.de">office(at)consumerise.de</a>
            <br />
            <br />
            <br />
            <h4>Disclaimer</h4>
            <h5>Liability for contents</h5>
            The contents of our pages were created with greatest care. However,
            we cannot assume any guarantee for the correctness, completeness and
            topicality of the contents. As a service provider we are responsible
            for our own contents on these pages in accordance with the general
            laws. As a service provider we are not obliged to monitor
            transmitted or stored third-party information or to investigate
            circumstances that indicate illegal activity. Obligations to remove
            or block the use of information in accordance with general laws
            remain unaffected by this. However, liability in this respect is
            only possible from the moment of knowledge of a concrete legal
            violation. As soon as we become aware of such infringements, we will
            remove these contents immediately.
            <br />
            <br />
            <h5>Liability for links</h5>
            Our offer contains links to external websites of third parties, on
            whose contents we have no influence. Therefore, we cannot assume any
            liability for these external contents. The respective provider or
            operator of the websites is always responsible for the contents of
            the linked websites. The linked websites were checked for possible
            legal infringements at the time of linking. Illegal contents were
            not recognizable at the time of linking. However, a permanent
            content-related control of the linked websites is not reasonable
            without concrete evidence of a legal violation. We will remove such
            links immediately upon becoming aware of any legal violations.
            <br />
            <br />
            <h5>Copyright</h5>
            The contents of this website are subject to copyright, unless
            otherwise indicated, and may not be distributed, modified or copied
            as a whole or in part without the prior written consent of
            Consumerise GmbH. The images integrated into this website may not be
            used without the prior written consent of Consumerise GmbH. Images
            contained on the websites are partially subject to copyright of
            third parties. Insofar as the content on this website was not
            created by the operator, the copyrights of third parties are
            respected. In particular, any contents of third parties are marked
            as such. Should you nevertheless become aware of a copyright
            infringement, we kindly ask for appropriate reference. As soon as we
            become aware of any legal violations, we will remove such content
            immediately.
            <br />
            <br />{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
